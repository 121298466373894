.container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0rem;
    padding: 0rem;
    justify-content: space-around;

  }
  
  .side-bar {
    width: 250px;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    z-index: 1;
    position: relative;
  }
  
  .inputStyle {
    width: 2rem !important;
    height: 2.5rem;
    margin: 0.2rem;
    padding: 0rem;
    font-size: 1.5rem;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.3);
  }

  .error {
    border: 1px solid red !important;
  }